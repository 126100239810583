import React from 'react';
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';

const styling = (theme) => ({
  body: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
  },
  container: {
    maxHeight: '50%',
    maxWidth: '50%',
  },
  description: {
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  divider: {
    background: theme.palette.common.white,
    margin: 'auto',
    marginBottom: 5,
    marginTop: 5,
    width: '90%',
  },
  header: {
    color: theme.palette.common.white,
    marginLeft: 10,
    marginRight: 10,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.common.white,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  subDescription: {
    color: theme.palette.common.white,
    marginTop: 10,
    textAlign: 'center',
  },
});

const iconStyling = {
  iconRight: {
    color: 'white',
    fontSize: 30,
    transform: 'scaleX(-1)',
  },
  iconLeft: {
    color: 'white',
    fontSize: 30,
  },
};

const UnderConstructionPage = () => {
  const classes = makeStyles(styling)();

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title} variant={'h2'}>
            {"Blue Rondo Games"}
          </Typography>
        </Box>
        <Box className={classes.headerContainer}>
          <BuildIcon style={iconStyling.iconLeft} />
          <Typography className={classes.header} variant={'h4'}>
            {"Under Construction"}
          </Typography>
          <BuildIcon style={iconStyling.iconRight} />
        </Box>
        <Divider classes={{root: classes.divider}} variant={'middle'} />
        <Typography className={classes.description} variant={'h6'}>
          {
            "Blue Rondo Games is a company born out of a shared love for\
            games-the spark of collaborative creativity that comes with making\
            them and the joy of bringing people together that comes with\
            playing them."
          }
        </Typography>
        <Typography className={classes.subDescription} variant={'h6'}>
          {
            "Blue Rondo Games is based in Southern California and was founded\
            by Rick Gardner in 2021."
          }
        </Typography>
      </Box>
    </Box>
  )
}

export default UnderConstructionPage
